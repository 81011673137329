import { App } from "@/App";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
if (!container) {
  throw new Error("root container not found");
}

createRoot(container).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
