import { HBEnsureLogin } from "@/components/auth/hb-ensure-login";
import { CLIENT_ID, useEnvConfig } from "@/env-config";
import { FaroButton, FaroText } from "@faro-lotv/flat-ui";
import {
  ArchivalStatus,
  ProjectDescriptor,
  useCoreApiClient,
} from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/** @returns a list of user projects */
export function Projects(): JSX.Element {
  const { env, region } = useParams();

  return (
    <HBEnsureLogin env={env} region={region}>
      <ProjectsUi />
    </HBEnsureLogin>
  );
}

function ProjectsUi(): JSX.Element {
  const { env, region } = useParams();
  const config = useEnvConfig();
  const coreApi = useCoreApiClient(config.coreApiUrl, CLIENT_ID);
  const navigate = useNavigate();

  const [projects, setProjects] = useState<ProjectDescriptor[]>([]);

  const openProject = useCallback(
    (id: string) => {
      navigate(`/${env}/${region}/${id}`);
    },
    [env, navigate, region],
  );

  useEffect(() => {
    const ac = new AbortController();
    coreApi
      .getAllUserProjects({ archivingState: ArchivalStatus.Active }, ac.signal)
      .then(setProjects);
    return () => ac.abort();
  }, [coreApi]);

  if (projects.length === 0) {
    return <div>Loading projects...</div>;
  }

  return (
    <Stack direction="column">
      <FaroText variant="heading20">Projects</FaroText>
      {projects.map((p) => (
        <FaroButton
          key={p.project.id}
          onClick={() => openProject(p.project.id)}
        >
          {p.project.id} - {p.project.name}
        </FaroButton>
      ))}
    </Stack>
  );
}
