import {
  selectActiveArea,
  selectIsDataSetFromActiveArea,
} from "@/store/areas-selectors";
import {
  selectHasDataVisible,
  selectHasPoseVisible,
} from "@/store/scene-selectors";
import { hideData, hidePose, showData, showPose } from "@/store/scene-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  FaroButton,
  FaroText,
  FaroTooltip,
  blue,
  neutral,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement } from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { useCallback } from "react";
import { canBeRendered } from "../renderers/element-data-renderer";
import { ContextMenu } from "./context-menu";
import { ExpandNodeIcon } from "./expand-node-icon";
import { TreeNodeProps } from "./tree";

/** @returns a single node in the tree */
export function TreeNode({ node, style }: TreeNodeProps): JSX.Element | null {
  const element = useAppSelector(selectIElement(node.data.id));
  const activeAreaId = useAppSelector(selectActiveArea);
  const activeArea = useAppSelector(selectIElement(activeAreaId));
  const isFromActiveArea = useAppSelector(
    selectIsDataSetFromActiveArea(node.data.id),
  );

  const hasPose =
    !!element?.pose?.pos || !!element?.pose?.rot || !!element?.pose?.scale;

  const dispatch = useAppDispatch();

  const isPoseVisible = useAppSelector(selectHasPoseVisible(node.data.id));
  const isDataVisible = useAppSelector(selectHasDataVisible(node.data.id));

  const togglePoseVisible = useCallback(
    (id: GUID, isVisible: boolean) => {
      if (isVisible) {
        dispatch(hidePose(id));
      } else {
        dispatch(showPose(id));
      }
    },
    [dispatch],
  );

  const toggleDataVisible = useCallback(
    (id: GUID, isVisible: boolean) => {
      if (isVisible) {
        dispatch(hideData(id));
      } else {
        dispatch(showData(id));
      }
    },
    [dispatch],
  );

  if (!element) return null;

  return (
    <Stack
      sx={{
        ...style,
        // Need to remain aligned to the rowHeight parameter
        height: "20px",
      }}
      direction="row"
      alignItems="center"
      gap={1}
    >
      <ExpandNodeIcon
        isExpanded={!node.isClosed}
        onClick={(ev) => {
          ev.stopPropagation();
          node.toggle();
        }}
        sx={{
          display: "flex",
          mr: 0.5,
          visibility: node.isLeaf ? "hidden" : "unset",
          fontSize: "1.125em",
        }}
      />
      <FaroText
        variant="bodyM"
        flexGrow={2}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            backgroundColor: node.isSelected ? blue[500] : `${neutral[500]}19`,
            cursor: "pointer",
          },
          color: node.isSelected ? "white" : neutral[800],
          backgroundColor: node.isSelected ? blue[500] : "white",
          userSelect: "none",
        }}
      >
        {`${element.name} - ${element.type}(${element.typeHint})`}
      </FaroText>
      {hasPose && (
        <FaroText
          sx={{
            minWidth: "66px",
            backgroundColor: "yellow",
            userSelect: "none",
          }}
          variant="bodyM"
        >
          With Pose
        </FaroText>
      )}
      {isFromActiveArea && (
        <FaroTooltip
          title={`Seen from ${activeArea?.name ?? activeArea?.id} Section(Area)`}
        >
          <FaroText
            sx={{
              backgroundColor: "green",
              color: "white",
              userSelect: "none",
            }}
            variant="bodyM"
          >
            From Active Area
          </FaroText>
        </FaroTooltip>
      )}
      <FaroTooltip title="Show Item Pose Gizmo">
        <FaroButton
          sx={{
            backgroundColor: isPoseVisible ? "green" : undefined,
            color: isPoseVisible ? "white" : undefined,
          }}
          onClick={(ev) => {
            ev.stopPropagation();
            togglePoseVisible(node.data.id, isPoseVisible);
          }}
          variant="ghost"
          size="xs"
        >
          P
        </FaroButton>
      </FaroTooltip>
      <FaroTooltip title="Show Item 3D Data">
        <FaroButton
          sx={{
            backgroundColor: isDataVisible ? "green" : undefined,
            color: isDataVisible ? "white" : undefined,
          }}
          onClick={(ev) => {
            ev.stopPropagation();
            toggleDataVisible(node.data.id, isDataVisible);
          }}
          variant="ghost"
          size="xs"
          disabled={!canBeRendered(node.data.element?.type ?? "")}
        >
          3D
        </FaroButton>
      </FaroTooltip>
      <ContextMenu element={element} node={node} />
    </Stack>
  );
}
