import { GUID } from "@faro-lotv/foundation";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type SceneState = {
  /** Ids of the elements to show the pose of in the 3d view */
  elementsPose: GUID[];

  /** Ids of the elements to show the 3d data of in the 3d view*/
  elementsData: GUID[];

  /** Id of the selected element in the tree */
  selected: GUID | undefined;
};

const initialState: SceneState = {
  elementsPose: [],

  elementsData: [],

  selected: undefined,
};

const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    showPose(state, action: PayloadAction<GUID[] | GUID>) {
      state.elementsPose = appendAndClone(state.elementsPose, action.payload);
    },
    hidePose(state, action: PayloadAction<GUID[] | GUID>) {
      state.elementsPose = filter(state.elementsPose, action.payload);
    },
    showData(state, action: PayloadAction<GUID[] | GUID>) {
      state.elementsData = appendAndClone(state.elementsData, action.payload);
    },
    hideData(state, action: PayloadAction<GUID[] | GUID>) {
      state.elementsData = filter(state.elementsData, action.payload);
    },
    setSelected(state, action: PayloadAction<GUID | undefined>) {
      state.selected = action.payload;
    },
  },
});

export const selectionReducer = selectionSlice.reducer;

export const { showPose, hidePose, showData, hideData, setSelected } =
  selectionSlice.actions;

function appendAndClone<T>(data: T[], toAppend: T | T[]): T[] {
  return [...data, ...(Array.isArray(toAppend) ? toAppend : [toAppend])];
}

function filter<T>(data: T[], toFilter: T | T[]): T[] {
  const toCheck = Array.isArray(toFilter) ? toFilter : [toFilter];
  return data.filter((el) => !toCheck.includes(el));
}
