import {
  selectElementToShowDataFor,
  selectElementToShowPoseFor,
} from "@/store/scene-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  Canvas,
  ExplorationControls,
  selectIElement,
  selectIElementWorldPosition,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { GUID, IElement } from "@faro-lotv/ielement-types";
import { GizmoHelper, GizmoViewport, Html } from "@react-three/drei";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Color, Object3D, Vector3 } from "three";
import { useEvents } from "./events";
import { ElementDataRenderer } from "./renderers/element-data-renderer";
import { ElementPoseRenderer } from "./renderers/element-pose-renderer";

/** Size of the gizmo box on the bottom right of the canvas */
const GIZMO_SIZE = 80;

/**
 * @returns a 3d view on the current project
 */
export function ProjectView(): JSX.Element {
  return (
    <Canvas style={{ width: "100%", height: "100%" }}>
      <MainScene />
    </Canvas>
  );
}

function MainScene(): JSX.Element {
  const store = useAppStore();
  const camera = useThree((s) => s.camera);
  const scene = useThree((s) => s.scene);

  useEffect(() => {
    scene.background = new Color("black");
  }, [scene]);

  useEffect(() => {
    camera.far = 10000;
    camera.updateProjectionMatrix();
  }, [camera]);

  const toShowPose = useAppSelector(selectElementToShowPoseFor, isEqual);
  const toShowData = useAppSelector(selectElementToShowDataFor, isEqual);

  const { lookAt, select } = useEvents();

  const [target, setTarget] = useState<Vector3>();

  const updateTarget = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      ev.stopPropagation();
      if (ev.delta > 1 || ev.object.name.includes("Pivot")) return;
      setTarget(ev.point);
      let targetObject: Object3D | null = ev.object;
      while (targetObject && !targetObject.name) {
        targetObject = targetObject.parent;
      }
      if (targetObject?.name) {
        select.emit(targetObject.name);
      }
    },
    [select],
  );

  useTypedEvent(lookAt, (id: GUID) => {
    const position = selectIElementWorldPosition(id)(store.getState());
    const target = new Vector3().fromArray(position);
    setTarget(target);
  });

  return (
    <group onClick={updateTarget}>
      <ExplorationControls target={target} />
      <GizmoHelper alignment="bottom-right" margin={[GIZMO_SIZE, GIZMO_SIZE]}>
        <GizmoViewport
          axisColors={["red", "green", "blue"]}
          labelColor="black"
        />
      </GizmoHelper>
      {target && (
        <Html
          position={target}
          style={{ width: "8rem", pointerEvents: "none", left: "10px" }}
        >
          <div>{`X: ${target.x.toFixed(3)}`}</div>
          <div>{`Y: ${target.y.toFixed(3)}`}</div>
          <div>{`Z: ${target.z.toFixed(3)}`}</div>
        </Html>
      )}
      {toShowPose.map((id) => (
        <NodeRenderer key={id} id={id} Renderer={ElementPoseRenderer} />
      ))}
      {toShowData.map((id) => (
        <NodeRenderer key={id} id={id} Renderer={ElementDataRenderer} />
      ))}
    </group>
  );
}

type NodeRendererProps = {
  id: GUID;
  Renderer(props: { element: IElement }): JSX.Element | null;
};

function NodeRenderer({ id, Renderer }: NodeRendererProps): JSX.Element | null {
  const element = useAppSelector(selectIElement(id));
  if (!element) return null;

  return <Renderer element={element} />;
}
