import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

export type PanelContainerProps = PropsWithChildren<{
  visible: boolean;
}>;

/** @returns a container for a panel in the project page ui */
export function PanelContainer({
  children,
  visible,
}: PanelContainerProps): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        visibility: visible ? "visible" : "collapse",
      }}
    >
      {children}
    </Box>
  );
}
