import {
  HBEnsureLogin,
  HBProjectAccess,
} from "@/components/auth/hb-ensure-login";
import { EventsContextProvider } from "@/components/events";
import { ProjectUi } from "@/components/project-page/project-ui";
import { useEnvAndRegion, useEnvConfig } from "@/env-config";
import { assert } from "@faro-lotv/foundation";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { useParams } from "react-router-dom";

/** @returns the page to explore a single project */
export function Project(): JSX.Element {
  const [env, region] = useEnvAndRegion();
  const config = useEnvConfig();
  const { projectId } = useParams();
  assert(projectId);

  return (
    <HBEnsureLogin env={env} region={region}>
      <HBProjectAccess projectId={projectId}>
        <ApiClientContextProvider apiBaseUrls={config} projectId={projectId}>
          <EventsContextProvider>
            <ProjectUi projectId={projectId} />
          </EventsContextProvider>
        </ApiClientContextProvider>
      </HBProjectAccess>
    </HBEnsureLogin>
  );
}
