import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { FaroButton, FaroText, LoadingScreen } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  addIElements,
  disableTreeFiltering,
  initializeProject,
  selectProjectName,
  setProjectName,
  setRootId,
} from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ProjectView } from "../project-view";
import { AreasPanel } from "./areas-panel";
import { SelectedElementPanel } from "./current-element-panel";
import { PanelContainer } from "./panel-container";
import { ProjectPanel } from "./project-panel";
import { ProjectTabs, UiTabs } from "./project-tabs";

type ProjectUiProps = {
  projectId: GUID;
};

/**
 * @returns the main ui of the project page
 */
export function ProjectUi({ projectId }: ProjectUiProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const projectName = useAppSelector(selectProjectName);

  const { projectApiClient: projectApi, coreApiClient: coreApi } =
    useApiClientContext();

  // Load the current project
  useEffect(() => {
    const ac = new AbortController();
    setIsLoading(true);
    dispatch(initializeProject(projectId));
    dispatch(disableTreeFiltering());
    const pApiPromise = projectApi
      .getAllIElements({ signal: ac.signal })
      .then((elements) => {
        dispatch(setRootId(elements[0].rootId));
        dispatch(addIElements(elements));
      });
    const cApiPromise = coreApi.getProjectInfo(projectId).then((info) => {
      dispatch(setProjectName(info.name));
    });
    Promise.allSettled([pApiPromise, cApiPromise]).then(() => {
      setIsLoading(false);
    });
    return () => ac.abort();
  }, [coreApi, dispatch, projectApi, projectId]);

  const [currentTab, setCurrentTab] = useState<UiTabs>(UiTabs.project);

  const store = useAppStore();
  const dumpProject = useCallback(() => {
    const dump = JSON.stringify(
      Object.values(store.getState().iElements.iElements),
      undefined,
      2,
    );

    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([dump], { type: "text/json " }));
    a.download = `${projectName}.json`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  }, [projectName, store]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Stack
      direction="row"
      justifyContent="stretch"
      sx={{ width: "100%", height: "100%" }}
    >
      <Stack direction="column" component="span" sx={{ width: "50%", m: 1 }}>
        <Stack direction="row" alignItems="center">
          <FaroText variant="heading20">{projectName}</FaroText>
          <FaroButton
            disabled={isLoading}
            variant="ghost"
            onClick={dumpProject}
          >
            Dump
          </FaroButton>
        </Stack>
        <ProjectTabs current={currentTab} onCurrentChanged={setCurrentTab} />
        <PanelContainer visible={currentTab === UiTabs.project}>
          <ProjectPanel />
        </PanelContainer>
        <PanelContainer visible={currentTab === UiTabs.currentElement}>
          <SelectedElementPanel />
        </PanelContainer>
        <PanelContainer visible={currentTab === UiTabs.areas}>
          <AreasPanel projectId={projectId} />
        </PanelContainer>
      </Stack>
      <Box component="span" sx={{ width: "50%" }}>
        <ProjectView />
      </Box>
    </Stack>
  );
}
