// Prototype logic to pick the url for the backends. This is a simplified version of the sphere-web-viewer web-service
// without a custom web-server that seem overkill for an initial prototype app
// We could considerate to generalize the sphere-web-viewer web-service so it can be used for multiple apps and remove this
// duplication in the future
import { assert } from "@faro-lotv/lotv";
import { mapValues } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

/** Urls of backend services used by the sphere viewer app */
export type EnvConfig = {
  /** Name of the environment */
  envName: string;

  /** Url of the user module to use for authentication */
  userModuleUrl: string;

  /** Base url for the core api */
  coreApiUrl: string;

  /** Base url for the project api */
  projectApiUrl: string;

  /** Base url of the progress api */
  progressApiUrl: string;

  /** Base url of the point cloud api */
  pointCloudApiUrl: string;

  /** Base url of the registration */
  registrationApiUrl: string;

  /** Base url for the external annotation provider */
  bcfServicesApiUrl: string;
};

const devEnvTemplate: EnvConfig = {
  envName: "holobuilder-{env}-{region}",
  userModuleUrl: "https://login.{env}.holobuilder.{region}/user-module",
  coreApiUrl: "https://core.api.{env}.holobuilder.{region}",
  progressApiUrl: "https://progress.api.{env}.holobuilder.{region}",
  pointCloudApiUrl: "https://pointcloud.api.{env}.holobuilder.{region}",
  projectApiUrl: "https://v2.project.api.{env}.holobuilder.{region}",
  registrationApiUrl: "https://cloud-reg.api.{env}.holobuilder.{region}",
  bcfServicesApiUrl: "https://cde.api.{env}.holobuilder.{region}",
};

/**
 * @returns the proper env configuration for the current dev or deploy environment
 * @param env the target environment
 * @param region the target region
 */
export function getEnvConfiguration(env?: string, region?: string): EnvConfig {
  assert(env && ["dev", "staging"].includes(env));
  assert(region && ["eu", "com"].includes(region));
  return mapValues(
    devEnvTemplate,
    (val) => val.replace("{env}", env).replace("{region}", region) || "unknown",
  );
}

/** @returns the EnvConfig for the current url */
export function useEnvConfig(): EnvConfig {
  const [env, region] = useEnvAndRegion();
  return getEnvConfiguration(env, region);
}

/** @returns the env and regions from the url params or from the origin */
export function useEnvAndRegion(): [string | undefined, string | undefined] {
  const { env: paramEnv, region: paramRegion } = useParams();

  return useMemo(() => {
    const env =
      paramEnv ?? (location.origin.includes(".dev") ? "dev" : "staging");
    const region =
      paramRegion ?? (location.origin.includes(".eu") ? "eu" : "com");
    return [env, region];
  }, [paramEnv, paramRegion]);
}

export const CLIENT_ID = "dev-tools/0.0.0";
