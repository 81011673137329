import { GUID } from "@faro-lotv/foundation";
import { RootState } from "./store";

/** @returns the current active area if available */
export function selectActiveArea({ areas }: RootState): GUID | undefined {
  return areas.activeAreaId;
}

/**
 * @param dataSetId to check
 * @returns true if the data set is part of the active area datasets
 */
export function selectIsDataSetFromActiveArea(dataSetId: GUID) {
  return (state: RootState) => {
    if (!state.areas.activeAreaId) return;
    return state.iElements.areaDataSets[state.areas.activeAreaId]?.find(
      (value) => value.elementId === dataSetId,
    );
  };
}
