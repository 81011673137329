import { FaroButton, FaroText, TextField } from "@faro-lotv/flat-ui";
import { Divider, Radio, RadioGroup, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const PROJECT_ID_LENGTH = 36;

/** @returns the Home Page ui to pick the environment/project to open */
export function Home(): JSX.Element {
  const navigate = useNavigate();

  const [env, setEnv] = useState("dev");
  const [region, setRegion] = useState("eu");
  const [projectId, setProjectId] = useState("");

  const listProjects = useCallback(() => {
    navigate(`/${env.toLowerCase()}/${region.toLowerCase()}`);
  }, [env, navigate, region]);

  const openProject = useCallback(() => {
    navigate(`/${env.toLowerCase()}/${region.toLowerCase()}/${projectId}}`);
  }, [env, navigate, projectId, region]);

  return (
    <Stack direction="column" gap={1}>
      <FaroText variant="heading20">Select Environment</FaroText>
      <RadioGroup value={env} onChange={(ev) => setEnv(ev.target.value)}>
        <MyRadio>DEV</MyRadio>
        <MyRadio>STAGING</MyRadio>
      </RadioGroup>
      <FaroText variant="heading20">Select Region</FaroText>
      <RadioGroup value={region} onChange={(ev) => setRegion(ev.target.value)}>
        <MyRadio>EU</MyRadio>
        <MyRadio>COM</MyRadio>
      </RadioGroup>
      <Divider />
      <Stack direction="row" alignItems="center" gap={1}>
        <FaroText variant="heading12">Project Id:</FaroText>
        <TextField
          controlStyle={{ flexGrow: 1 }}
          text={projectId}
          onTextChanged={setProjectId}
        />
        <FaroButton
          onClick={openProject}
          disabled={projectId.length !== PROJECT_ID_LENGTH}
        >
          Open Project
        </FaroButton>
      </Stack>
      <FaroButton onClick={listProjects}>List Projects</FaroButton>
    </Stack>
  );
}

type MyRadioProps = {
  children: string;
};

function MyRadio({ children }: MyRadioProps): JSX.Element {
  return (
    <Stack direction="row" alignItems="center">
      <Radio value={children.toLowerCase()} />
      <FaroText variant="bodyM">{children}</FaroText>
    </Stack>
  );
}
