import { GUID, TypedEvent, assert } from "@faro-lotv/foundation";
import { PropsWithChildren, createContext, useContext, useState } from "react";

/** Events that can be triggered by the tree view that can impact the 3d view */
type Events = {
  /** Notify that the user want to frame a specific element in the 3d view */
  lookAt: TypedEvent<GUID>;

  /** Ask the tree view to show and select a specific element */
  select: TypedEvent<GUID>;
};

/** Context to expose all the events */
const EventsContext = createContext<Events | undefined>(undefined);

/** @returns a context providers for the scene graph explorer events */
export function EventsContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [events] = useState({
    lookAt: new TypedEvent<GUID>(),
    select: new TypedEvent<GUID>(),
  });

  return (
    <EventsContext.Provider value={events}>{children}</EventsContext.Provider>
  );
}

/** @returns the scene graph explorer events */
export function useEvents(): Events {
  const ctx = useContext(EventsContext);

  assert(ctx);

  return ctx;
}
