import { iElementsReducer } from "@faro-lotv/project-source";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { areasReducers } from "./areas-slice";
import { selectionReducer } from "./scene-slice";

export const rootReducer = combineReducers({
  iElements: iElementsReducer,
  selection: selectionReducer,
  areas: areasReducers,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
