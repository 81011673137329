import { Tab, Tabs } from "@mui/material";

/** The available tabs in the app */
export enum UiTabs {
  /** A tab to explore the project */
  project = 0,

  /** A tab to check the current selected element */
  currentElement = 1,

  /** A tab to explore the alignment edges */
  areas = 2,
}

export type ProjectTabsProps = {
  /** Current tab active */
  current: UiTabs;

  /** Signal that the current tab changed */
  onCurrentChanged(tab: UiTabs): void;
};

/** @returns a tab bar to select what project tab is active in the project page */
export function ProjectTabs({
  current,
  onCurrentChanged,
}: ProjectTabsProps): JSX.Element {
  return (
    <Tabs value={current} onChange={(_, value) => onCurrentChanged(value)}>
      <Tab value={UiTabs.project} label="Project" />
      <Tab value={UiTabs.currentElement} label="Selected Element" />
      <Tab value={UiTabs.areas} label="Areas" />
    </Tabs>
  );
}
