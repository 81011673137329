import { selectSelected } from "@/store/scene-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { ElementView } from "../element-view";

/** @returns a panel to view and interact with the project tree*/
export function SelectedElementPanel(): JSX.Element {
  const selectedId = useAppSelector(selectSelected);

  if (!selectedId) {
    return <div>"No Selection"</div>;
  }

  return <ElementView id={selectedId} />;
}
