import { FaroButton, useToast } from "@faro-lotv/flat-ui";
import {
  validateNotNullishObject,
  validateOfType,
} from "@faro-lotv/foundation";
import { IPose, validateQuat, validateVec3 } from "@faro-lotv/ielement-types";
import { Box, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";

/** A local pose of a dataset inside an area */
export type LocalPose = Required<Pick<IPose, "rot" | "pos" | "scale">>;

export type PoseEditorProps = {
  /** The dataset pose */
  pose: LocalPose;

  /** Notify the pose was edited */
  onChanged(newPose: LocalPose): void;
};

/** @returns a component to display and edit a local pose */
export function PoseEditor({ pose, onChanged }: PoseEditorProps): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);

  const { openToast } = useToast();

  const [poseText, setPoseText] = useState("");

  const startEditing = useCallback(() => {
    setPoseText(JSON.stringify(pose, undefined, 2));
    setIsEditing(true);
  }, [pose]);

  const confirmEditing = useCallback(() => {
    try {
      const parsed = JSON.parse(poseText);
      if (!isValidLocalPose(parsed)) {
        throw new Error("Invalid pose json");
      }
      onChanged(parsed);
      setIsEditing(false);
    } catch (reason) {
      console.log(reason);
      openToast({
        title: "Invalid pose",
        variant: "error",
        persist: false,
      });
    }
  }, [onChanged, openToast, poseText]);

  const cancelEditing = useCallback(() => {
    setIsEditing(false);
  }, []);

  if (!isEditing) {
    return (
      <Stack direction="row" gap={1}>
        <FaroButton onClick={startEditing}>Edit</FaroButton>
        <Box component="pre" flexGrow={1}>
          {JSON.stringify(pose, undefined, 2)}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={1} width="100%">
      <Stack>
        <FaroButton onClick={confirmEditing}>Confirm</FaroButton>
        <FaroButton onClick={cancelEditing}>Cancel</FaroButton>
      </Stack>
      <TextField
        sx={{ flexGrow: 1 }}
        multiline
        value={poseText}
        onChange={(ev) => setPoseText(ev.target.value)}
      />
    </Stack>
  );
}

/**
 * @param data to check
 * @returns true if it matches the DataSetLocalPose type
 */
function isValidLocalPose(data: unknown): data is LocalPose {
  if (!validateNotNullishObject(data, "LocalPose")) {
    return false;
  }

  const pose: Partial<LocalPose> = data;

  return (
    validateOfType(pose, "pos", validateVec3) &&
    validateOfType(pose, "scale", validateVec3) &&
    validateOfType(pose, "rot", validateQuat)
  );
}
