// TODO: Consolidate with sphere-web-viewer after the gate-keeper package is available (https://faro01.atlassian.net/browse/SWEB-1706)
import { getEnvConfiguration } from "@/env-config";
import { Stack } from "@mui/system";
import {
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { AuthContext } from "./auth-context";

/** Message received from UserModule asking the page to redirect to a new url */
type RedirectMessage = {
  /** New url the app should redirect to */
  redirect: string;
};

/** Message received from UserModule when the UserStatus changes */
type UserStatusMessage = {
  /** Property used to identify this was a UserStatusMessage, always true */
  userStatus: true;

  /**
   * If defined, it is true if the user is logged in, returned when the iframe is initialized
   * or after a successful login
   */
  isUserLoggedIn?: boolean;

  /** If defined, it is true if the user has just logged out, returned after a explicit logout */
  isUserLoggedOut?: boolean;
};

/** All UserModule messages we currently handle */
type IFrameMessage = RedirectMessage | UserStatusMessage;

/**
 * @param message the UserModule message
 * @returns true if the passed UserModule message is a RedirectMessage
 */
function isRedirectMessage(message: IFrameMessage): message is RedirectMessage {
  return "redirect" in message;
}

/**
 * @param message the UserModule message
 * @returns true if the passed UserModule message is a UserStatusMessage
 */
function isUserStatusMessage(
  message: IFrameMessage,
): message is UserStatusMessage {
  return "userStatus" in message;
}

/**
 * The HoloBuilder Auth Provider will manage the authentication with the HoloBuilder system
 * using the UserModule iframe.
 *
 * When the app start will inject the UserModule iframe and use that to check if the user it
 * authenticated already or to manage the authentication workflow
 *
 * @returns HoloBuilder authentication provider
 */
export function HBAuthProvider({
  children,
}: PropsWithChildren): JSX.Element | null {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userModuleUrl, setUserModuleUrl] = useState<string | undefined>();

  /**
   * True while the UserModule should be shown to the user.
   * It will always be put into DOM initially in order to check if the user is logged in already,
   * and it will be made visible if the user is not logged in
   */
  const [shouldShowUserModule, setShouldShowUserModule] =
    useState<boolean>(false);

  const [iFrameUrl, setIFrameUrl] = useState<string | undefined>(() =>
    // Initialize with the login url so we receive the window event with the login state
    userModuleUrl ? getLoginUrl(userModuleUrl) : undefined,
  );

  // Attach/detach callback for messages coming from the UserModule
  useLayoutEffect(() => {
    function onWindowMessage(message: MessageEvent<IFrameMessage>): void {
      if (!userModuleUrl || message.origin !== new URL(userModuleUrl).origin) {
        return;
      }

      if (isUserStatusMessage(message.data)) {
        if (message.data.isUserLoggedIn !== undefined) {
          setIsLoggedIn(message.data.isUserLoggedIn);
          if (message.data.isUserLoggedIn) {
            setShouldShowUserModule(false);
          }
        }

        if (message.data.isUserLoggedOut) {
          setIsLoggedIn(false);
          // Reloading the page so we make sure to not leak authenticated user data into unauthenticated context
          window.location.reload();
        }
      } else if (isRedirectMessage(message.data)) {
        window.location.replace(message.data.redirect);
      }
    }

    window.addEventListener("message", onWindowMessage);

    return () => window.removeEventListener("message", onWindowMessage);
  }, [userModuleUrl]);

  const requestLogin = useCallback(
    (env?: string, region?: string) => {
      const config = getEnvConfiguration(env, region);
      if (userModuleUrl === config.userModuleUrl && isLoggedIn) return;
      setUserModuleUrl(config.userModuleUrl);
      setIFrameUrl(() => getLoginUrl(config.userModuleUrl));
      setShouldShowUserModule(true);
    },
    [isLoggedIn, userModuleUrl],
  );

  const logout = useCallback(() => {
    if (!userModuleUrl) return;
    setIFrameUrl(() => getLogoutUrl(userModuleUrl));
    setShouldShowUserModule(true);
    setIsLoggedIn(false);
  }, [userModuleUrl]);

  const value = useMemo<AuthContext>(
    () => ({
      isLoggedIn,
      requestLogin,
      logout,
    }),
    [isLoggedIn, logout, requestLogin],
  );

  return (
    <>
      {/** Add hidden core module iframe to the page if the user is not logged in even if login is not needed */}
      {/** In this way if the user is already logged in from another source (Eg. dashboard) we get a valid cookie */}
      {!isLoggedIn && (
        <Stack
          // Only make UserModule visible in case the user has to interact with it
          visibility={shouldShowUserModule ? "visible" : "hidden"}
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            alignItems: "center",
            backgroundColor: "userModuleBackground",
          }}
          direction="column"
        >
          <iframe
            style={{
              width: "100%",
              maxWidth: "800px",
              flexGrow: 1,
            }}
            frameBorder={0}
            src={iFrameUrl}
          />
        </Stack>
      )}

      {!shouldShowUserModule && (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      )}
    </>
  );
}

/**
 * @returns The URL to the login page of the user module that redirects back to the current page
 * @param userModuleUrl Base URL of the user module
 */
function getLoginUrl(userModuleUrl: string): string {
  return `${userModuleUrl}#login?os=web&redirectUrl=${encodeURIComponent(
    window.location.href,
  )}`;
}

/**
 * @returns The URL to the logout page of the user module
 * @param userModuleUrl Base URL of the user module
 */
function getLogoutUrl(userModuleUrl: string): string {
  return `${userModuleUrl}#logout`;
}
