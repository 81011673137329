import { selectActiveArea } from "@/store/areas-selectors";
import { setActiveArea } from "@/store/areas-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FaroButton, FaroText, FaroTooltip } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement, setAreaDataSets } from "@faro-lotv/project-source";
import { DataSetAreaInfo } from "@faro-lotv/service-wires";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Stack,
} from "@mui/material";
import { useCallback } from "react";
import { LocalPose, PoseEditor } from "../editors/pose-editor";

/** A mapping between AreaIds and the datasets in that area volume */
export type AreaDataSets = Record<GUID, DataSetAreaInfo[]>;

export type AreaDataSetViewProps = {
  /** The area->datasets volume mapping */
  areaDataSets: AreaDataSets;

  /** Reset the mapping to the default value from the backend */
  onReset(): void;

  /** Modify the area->datasets mapping from the UI */
  onAreaTreeChanged(newAreaTree: AreaDataSets): void;
};

/** @returns a tree view on top of the area datasets */
export function AreaDataSetView({
  areaDataSets,
  onReset,
  onAreaTreeChanged,
}: AreaDataSetViewProps): JSX.Element {
  const dispatch = useAppDispatch();

  const resetAreaMapping = useCallback(() => {
    dispatch(setAreaDataSets({}));
    dispatch(setActiveArea(undefined));
    onReset();
  }, [dispatch, onReset]);

  const updateAreaDataSets = useCallback(
    (areaId: GUID, newDataSets: DataSetAreaInfo[]) => {
      onAreaTreeChanged({
        ...areaDataSets,
        [areaId]: newDataSets,
      });
    },
    [areaDataSets, onAreaTreeChanged],
  );

  return (
    <Stack sx={{ height: "100%" }}>
      <FaroTooltip title="Remove all area->dataset mapping form the app, mark no area as active">
        <FaroButton sx={{ width: "100%" }} onClick={resetAreaMapping}>
          Reset
        </FaroButton>
      </FaroTooltip>
      {Object.entries(areaDataSets).map(([id, dataSets]) => (
        <AreaDataSetsSection
          key={id}
          areaId={id}
          dataSets={dataSets}
          onAreaChanged={updateAreaDataSets}
        />
      ))}
    </Stack>
  );
}

type AreaDataSetsSectionProps = {
  /** Id of the area */
  areaId: GUID;

  /** Datasets in the area volume */
  dataSets: DataSetAreaInfo[];

  /** Notify the user edited this area */
  onAreaChanged(areaId: GUID, dataSets: DataSetAreaInfo[]): void;
};

function AreaDataSetsSection({
  areaId,
  dataSets,
  onAreaChanged,
}: AreaDataSetsSectionProps): JSX.Element {
  const areaElement = useAppSelector(selectIElement(areaId));
  const dispatch = useAppDispatch();

  const isActive = useAppSelector(selectActiveArea) === areaId;

  const makeAreaActive = useCallback(() => {
    dispatch(setAreaDataSets({ [areaId]: dataSets }));
    dispatch(setActiveArea(areaId));
  }, [areaId, dataSets, dispatch]);

  const updateArea = useCallback(
    (elementId: GUID, newPose: LocalPose) => {
      const newDataSets = dataSets.map((value) => {
        if (value.elementId !== elementId) return value;
        return {
          ...value,
          pose: newPose,
        };
      });
      onAreaChanged(areaId, newDataSets);
      if (isActive) {
        dispatch(setAreaDataSets({ [areaId]: newDataSets }));
      }
    },
    [areaId, dataSets, dispatch, isActive, onAreaChanged],
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <FaroText variant="bodyM">Area: {areaElement?.name}</FaroText>
          <FaroButton
            disabled={isActive}
            onClick={(ev) => {
              ev.stopPropagation();
              makeAreaActive();
            }}
          >
            {isActive ? "Active" : "Make Active"}
          </FaroButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List sx={{ py: 0, px: 1 }}>
          {dataSets.map((info) => (
            <DataSetSection
              key={info.elementId}
              {...info}
              onPoseChanged={updateArea}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

type DataSetSectionProps = DataSetAreaInfo & {
  /** Notify the user edited the pose of this dataset inside the area */
  onPoseChanged(elementId: GUID, newPose: LocalPose): void;
};

function DataSetSection({
  elementId,
  pose,
  onPoseChanged,
}: DataSetSectionProps): JSX.Element {
  const dataSet = useAppSelector(selectIElement(elementId));

  return (
    <ListItem>
      <Stack direction="column" sx={{ width: "100%" }}>
        <FaroText variant="bodyM">DataSet: {dataSet?.name}</FaroText>
        <FaroText variant="heading12">Area Pose</FaroText>
        <PoseEditor
          pose={pose}
          onChanged={(newPose) => onPoseChanged(elementId, newPose)}
        />
      </Stack>
    </ListItem>
  );
}
