import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { SvgIconProps, SxProps } from "@mui/material";

export type ExpandNodeIconProps = {
  /** Boolean to know if the node is expanded or closed */
  isExpanded: boolean;

  /** Optional style to apply to the image */
  sx?: SxProps;

  /** Callback called when the icon is clicked */
  onClick?: SvgIconProps["onClick"];
};

/**
 * This component is required, because by simply rotating, with css, the image will become blurry.
 *
 * @returns an arrow icon. The arrow points to below if the node is expanded, otherwise it points to the right.
 */
export function ExpandNodeIcon({
  isExpanded,
  sx,
  onClick,
}: ExpandNodeIconProps): JSX.Element {
  return isExpanded ? (
    <ExpandMoreSharpIcon sx={sx} onClick={onClick} />
  ) : (
    <ChevronRightSharpIcon sx={sx} onClick={onClick} />
  );
}
