import { useAppSelector } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import {
  selectIElement,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { Box } from "@mui/material";

export type ElementViewProps = {
  id: GUID;
};

/** @returns a view of the details of a single element */
export function ElementView({ id }: ElementViewProps): JSX.Element | null {
  const element = useAppSelector(selectIElement(id));
  const trafo = useAppSelector(selectIElementWorldTransform(id));

  if (!element) return null;

  return (
    <Box
      component="div"
      sx={{ height: "90%", width: "100%", overflow: "auto" }}
    >
      <pre>
        <div>
          {element.name} - {element.type}({element.typeHint})
        </div>
        <div>World Transformation</div>
        <code>{JSON.stringify(trafo, undefined, 1)}</code>
        <div>IElement Description</div>
        <code>{JSON.stringify(element, undefined, 1)}</code>
      </pre>
    </Box>
  );
}
