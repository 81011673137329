import { GUID } from "@faro-lotv/foundation";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type AreasSlice = {
  /** Ids of the active area, used to position elements */
  activeAreaId: GUID | undefined;
};

const initialState: AreasSlice = {
  activeAreaId: undefined,
};

const areasSlice = createSlice({
  name: "areas",
  initialState,
  reducers: {
    setActiveArea(state, action: PayloadAction<GUID | undefined>) {
      state.activeAreaId = action.payload;
    },
  },
});

export const areasReducers = areasSlice.reducer;

export const { setActiveArea } = areasSlice.actions;
