import { GUID } from "@faro-lotv/foundation";
import { AreaDataSetView } from "../area-datasets-tree/area-datasets-view";
import { useLoadAreaDataSets } from "../area-datasets-tree/use-load-area-datasets";

type AreasPanelProps = {
  projectId: GUID;
};

/** @returns a panel to inspect the data for each area */
export function AreasPanel({ projectId }: AreasPanelProps): JSX.Element {
  const { areaDataSets, setAreaDataSets, reload } =
    useLoadAreaDataSets(projectId);

  if (!areaDataSets) {
    return <div>Loading</div>;
  }

  if (areaDataSets instanceof Error) {
    return <div>Unable to load areas: {areaDataSets.message}</div>;
  }

  return (
    <AreaDataSetView
      areaDataSets={areaDataSets}
      onReset={() => reload()}
      onAreaTreeChanged={setAreaDataSets}
    />
  );
}
